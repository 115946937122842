import axios from "axios";
import io from "socket.io-client";
import {appConfig} from "../config/AppConfig";

export const API_URL = appConfig.apiUrl;
export const socket = io.connect(API_URL);

const api = axios.create({
    baseURL: API_URL
});

export const getData = async (endPoint, accessToken) => {
    try {
        const response = await api.get(endPoint, {
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const postData = async (endPoint, formData, accessToken) => {
    try {
        const response = await api.post(endPoint, formData, {
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        });
        return response.data;
    } catch (error) {
        return error;
    }
};
