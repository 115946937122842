import HomeInfoBox from "../components/HomeInfoBox";
import Motivation from "../components/Motivation";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useUserAuth} from "../context/UserAuthContext"
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {API_URL} from "../helpers/HttpService";
import {HomeFirstHelp} from "./HomeFirst";

/**
 * Home content
 * Function to create HomeInfoBox Components filled with info provided from homeData.js
 * @returns Home Page
 */

function Home() {
    const {user} = useUserAuth();
    const {t, i18n} = useTranslation()
    const [popUp, setPopUp] = useState(false)

    const togglePopUp = async () => {
        setPopUp(!popUp)
    }

    useEffect(() => {


        const getContent = async () => {
            try {
                let response = await axios.get(API_URL + `homePage/`, {})

                // console.log(response)

                setContent1(response.data
                    ?.[0].content1)
                setContent1en(response.data
                    ?.[0].content1en)
                setContent2Title(response.data
                    ?.[0].content2title)
                setContent2Link(response.data
                    ?.[0].content2link)
                setContent2(response.data
                    ?.[0].content2)
                setContent2en(response.data
                    ?.[0].content2en)
                setContent3Title(response.data
                    ?.[0].content3title)
                setContent3Link(response.data
                    ?.[0].content3link)
                setContent3(response.data
                    ?.[0].content3)
                setContent3en(response.data
                    ?.[0].content3en)
                setContent4Title(response.data
                    ?.[0].content4title)
                setContent4Link(response.data
                    ?.[0].content4link)
                setContent4(response.data
                    ?.[0].content4)
                setContent4en(response.data
                    ?.[0].content4en)


            } catch (err) {
                if (err.response) {
                    console.log(err.response.data)
                    console.log(err.response.status)
                    console.log(err.response.headers)
                } else {
                    console.log(`error ${err.message}`)
                }
            }
        }
        getContent();


    }, []);

    const [content1, setContent1] = useState('');
    const [content1en, setContent1en] = useState('');
    const [content2Title, setContent2Title] = useState('');
    const [content2, setContent2] = useState('');
    const [content2link, setContent2Link] = useState('');
    const [content2en, setContent2en] = useState('');
    const [content3Title, setContent3Title] = useState('');
    const [content3, setContent3] = useState('');
    const [content3link, setContent3Link] = useState('');
    const [content3en, setContent3en] = useState('');
    const [content4Title, setContent4Title] = useState('');
    const [content4, setContent4] = useState('');
    const [content4link, setContent4Link] = useState('');
    const [content4en, setContent4en] = useState('');

    return (
        <div>

            {popUp ?
                <HomeFirstHelp/> : null}

            <div className="container-contents">

                {user.email?.includes("@united-parents.de") || user.email?.includes("christina.bender@stud.fra-uas.de") || user.email === "Denis.Neumann@outlook.de" ?
                    <Link className="button go-to" to="/editHomePage">Seite Bearbeiten</Link> : null}
                <Motivation togglePopUp={togglePopUp} content={content1} contentEN={content1en}/>
                {content2?.length > 0 ? i18n.language === 'en' && content2en?.length > 0 ?
                    <HomeInfoBox
                        imgSrc="/images/fra-uas-logo.svg"
                        title={content2Title}
                        content={content2en}
                        linkURL={content2link}
                    /> :
                    <HomeInfoBox
                        imgSrc="/images/fra-uas-logo.svg"
                        title={content2Title}
                        content={content2}
                        linkURL={content2link}
                    /> : null}
                {content3?.length > 0 ? i18n.language === 'en' && content3en?.length > 0 ?
                    <HomeInfoBox
                        imgSrc="/images/fra-uas-logo.svg"
                        title={content3Title}
                        content={content3en}
                        linkURL={content3link}
                    /> :
                    <HomeInfoBox
                        imgSrc="/images/fra-uas-logo.svg"
                        title={content3Title}
                        content={content3}
                        linkURL={content3link}
                    /> : null}
                {content4.length > 0 ? i18n.language === 'en' && content4en?.length > 0 ?
                    <HomeInfoBox
                        imgSrc="/images/fra-uas-logo.svg"
                        title={content4Title}
                        content={content4en}
                        linkURL={content4link}
                    /> :
                    <HomeInfoBox
                        imgSrc="/images/fra-uas-logo.svg"
                        title={content4Title}
                        content={content4}
                        linkURL={content4link}
                    /> : null}
            </div>
        </div>

    );
}

export default Home;