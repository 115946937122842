import axios from "axios";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useUserAuth} from "../context/UserAuthContext";
import {useTranslation} from "react-i18next";
import {API_URL, getData} from "../helpers/HttpService";

function SearchUser() {
    const {user} = useUserAuth()

    const [userData, setUserData] = useState("")
    const [key, setKey] = useState("")
    const [blockUsers, setBlockUsers] = useState([])

    const {t} = useTranslation()


    /* Prevent that users can enter more than 5 digits */
    const handleInput = async (e) => {
        if(e) {
            let maxLength = 20;
            if (e.target.value.length > maxLength) {
                e.target.value = e
                    .target
                    .value
                    .slice(0, maxLength);
                setKey(e.target.value)
            }
        }
    }
    const getBlockUsers = () => {
        //load list of blocked users
        let temparray = []
        axios.get(API_URL + `users/blockedusers/${user.uid}`, {
            headers: {
                Authorization: 'Bearer ' + user.accessToken
            }
        }).then((res) => {
            res.data.blockedUsers.map(item => {
                // console.log(item)
                if (!(temparray.some(element => element.id === item.id))) {
                    temparray = [...temparray, item.id]
                }
            })
            setBlockUsers(temparray)
        })
    }


    useEffect(() => {
        handleInput()
    }, [])

    useEffect(() => {
        getBlockUsers()
    }, [])

    const search = () => {
        axios.get(API_URL + `searchUser/plzcity/${key}`, {
            headers: {
                Authorization: 'Bearer ' + user.accessToken
            }
        }).then(res => {
            setUserData(res.data)
        }).catch(err => console.log(err))
    }

    const getDataName = () => {
        // console.log("key: ", key)
        axios.get(API_URL + `searchUser/username/${key}`).then(res => {
            setUserData(res.data)
            // console.log("DATA: ", res.data)
        }).catch(err => console.log(err))
    }

    return (
        <div className="container-contents">
            <div className="container-search-zip">
                <form id="zip-code">
                    <input
                        //type="number"
                        //onInput={handleInput}
                        placeholder={t("namecityorzip")}
                        value={key}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault()
                            }
                        }}
                        onChange={(e) => {
                            setKey(e.target.value)
                        }}
                        className="zip-input"></input>
                </form>
                <button className="button stay search-zip"
                        onClick={search}>{t("search")} {t("city")} / {t("zipcode")}</button>

                <button className="button stay search-zip"
                        onClick={getDataName}>{t("search") + " " + t("username")}</button>


                {
                    userData?.length > 0 ? userData?.map((item, index) =>
                        item.uid !== user.uid && !(blockUsers.some(element => element === item.uid))
                        && !(item.blockedUsers.some(element => element.id === user.uid)) ?
                            <div className="zip-result-row">
                                <div className="column">
                                    <div className="zip-result-card">
                                        <Link to={'/userProfile'} state={{uid: item.uid}}>
                                            <img className="circle-img"
                                                 src={`https://firebasestorage.googleapis.com/v0/b/united-parents-hub.appspot.com/o/images%2F${item.uid}?alt=media&token=b7213d01-a694-4828-9b90-e7edb5b92df1`}
                                                 alt="user-img"
                                                 onError={(e) => {
                                                     if (e.target.src !== "/images/Profile_avatar_placeholder_large.png") {
                                                         e.target.onError = null;
                                                         e.target.src = '/images/Profile_avatar_placeholder_large.png'
                                                     }
                                                 }
                                                 }
                                            ></img>
                                            <h1>{item.username}</h1>
                                        </Link>
                                        {item.kidsNumb !== "" ?
                                            <div>
                                                <p>{t("children") + ":"}</p>
                                                <p>{item.kidsNumb + " (" + item.kidsAge + ")"}</p>
                                            </div>
                                            :
                                            <div>
                                                <p>{t("children") + ":"}</p>
                                                <p>{t("noinfo")}</p>
                                            </div>
                                        }
                                        {/* Catch corresponding user ZIP code from DB  */}
                                        <p>{item.plz}</p>
                                        {/* Link user to chat page with corresponding user */}
                                        <Link to={"/chats"} state={{receiver: item.uid}}>
                                            <button className="button go-to send-msg">
                                                <span className="material-icons">forum</span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div> : null
                    ) : userData === "" ?
                        <div className="box-content no-results">
                            <h1>{t("findothers")}</h1>
                            <p>{t("enterziptofind")}.</p>
                            <p>{t("findwithusername")}.</p>
                        </div> : <div className="box-content no-results">
                            <h1>{t("nouserfound")}!</h1>
                            <p>{t("changenameorzipcode")}.</p>
                        </div>
                }
            </div>

        </div>

    );

}

export default SearchUser;
