import React, {Suspense} from "react";
import {Route, Routes} from "react-router-dom";
import {UserAuthContextProvider} from "../context/UserAuthContext"
import Login from "../pages/Login";
import Home from "../pages/Home";
import MyProfile from "../pages/MyProfile";
import SearchUser from "../pages/SearchUser";
import Chats from "../pages/Chats";
import Help from "../pages/HelpFinances";
import Settings from "../pages/Settings";
import ProtectedRoutes from "./ProtectedRoutes";
import Registration from "../pages/Registration";
import Registration2 from "../pages/Registration2";
import SidebarLogReg from "./SidebarLogReg";
import EditProfile from "../pages/EditProfile";
import EditHomePage from "../pages/EditHomePage";
import EditHelpAndFinance from "../pages/EditHelpAndFinance";
import GuHelpFinances from "../pages/GuHelpFinances";
import UserProfile from "../pages/UserProfile"
import Password from "../pages/ForgotPassword";
import HomeFirst from "../pages/HomeFirst";
import {DSGVO, Terms} from "../utils/termsconditions";
import {TermsConds} from "../pages/TermsConditions";
import {Impressum} from "../utils/impressum";
import Footer from "./Footer";
import GuHome from "../pages/GuHome";
import {Kontakt} from "../pages/Kontakt";
import {WaitForEmailVerification} from "../pages/WaitForEmailVerification";

/**
 * This is the entry point of the Web App
 * Contains:
 * Sidebar for the Login & Registration pages (with UP Logo & Welcome text)
 * routes to all pages
 */

function App() {

    return (
        <div>
            <Suspense fallback={<div className="container-contents"><h1>Loading...</h1></div>}>
                <SidebarLogReg/>
                <div className="pagecontainer">
                    <UserAuthContextProvider>
                        <Routes>
                            <Route path="/my-profile" element={<ProtectedRoutes><MyProfile/></ProtectedRoutes>}/>
                            <Route path="/search-user" element={<ProtectedRoutes><SearchUser/></ProtectedRoutes>}/>
                            <Route path="/chats" element={<ProtectedRoutes><Chats/></ProtectedRoutes>}/>
                            <Route path="/help-finances" element={<ProtectedRoutes><Help/></ProtectedRoutes>}/>
                            {/* Route for Goethe Uni: */}
                            <Route path="/Ghelp-finances"
                                   element={<ProtectedRoutes><GuHelpFinances/></ProtectedRoutes>}/>
                            <Route path="/editProfile" element={<ProtectedRoutes><EditProfile/></ProtectedRoutes>}/>
                            <Route path="/userProfile" element={<ProtectedRoutes><UserProfile/></ProtectedRoutes>}/>
                            <Route path="/editHomePage" element={<ProtectedRoutes><EditHomePage/></ProtectedRoutes>}/>
                            <Route path="/editHelpAndFinance"
                                   element={<ProtectedRoutes><EditHelpAndFinance/></ProtectedRoutes>}/>
                            <Route path="/settings" element={<ProtectedRoutes><Settings/></ProtectedRoutes>}/>
                            <Route path="/home" element={<ProtectedRoutes><Home/></ProtectedRoutes>}/>
                            <Route path="/Ghome" element={<ProtectedRoutes><GuHome/></ProtectedRoutes>}/>
                            <Route path="/" element={<Login/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/resetpassword" element={<Password/>}/>
                            <Route path="/registration" element={<Registration/>}/>
                            <Route path="/registration2" element={<ProtectedRoutes><Registration2/></ProtectedRoutes>}/>
                            <Route path="/homefirst" element={<ProtectedRoutes><HomeFirst/></ProtectedRoutes>}/>
                            <Route path="/terms" element={<TermsConds><Terms/></TermsConds>}/>
                            <Route path="/dsgvo" element={<TermsConds><DSGVO/></TermsConds>}/>
                            <Route path="/impressum" element={<TermsConds><Impressum/></TermsConds>}/>
                            <Route path="/kontakt" element={<Kontakt/>}/>
                            <Route path="/email-verification" element={<ProtectedRoutes logoutOnlySidebar={true}><WaitForEmailVerification/></ProtectedRoutes>}/>
                        </Routes>
                    </UserAuthContextProvider>
                </div>
                <Footer/>
            </Suspense>
        </div>
    );
}

export default App;
