import React, {useEffect, useState} from "react";
import {useUserAuth} from "../context/UserAuthContext";
import {Accordion} from "react-bootstrap";
import {deleteUser, getAuth, sendPasswordResetEmail} from "firebase/auth";
import {Link, useNavigate} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {DSGVO} from "../utils/termsconditions";
import {Impressum} from "../utils/impressum";
import axios from "axios";
import {API_URL} from "../helpers/HttpService";

const Settings = () => {
    const navigate = useNavigate()
    const {user} = useUserAuth();
    const auth = getAuth();
    const userData = auth.currentUser;
    const [blockedUsers, setBlockedUsers] = useState([])
    const [block, setBlocked] = useState(false)
    const {t, i18n} = useTranslation()

    const getBlockedUsers = () => {
        //load list of blocked users
        let temparray = []
        axios.get(API_URL + `users/blockedusers/${user.uid}`, {
            headers: {
                Authorization: 'Bearer ' + user.accessToken
            }
        }).then((res) => {
            res.data.blockedUsers.map(async (item) => {
                if (!(blockedUsers.some(element => element.id === item.id))) {

                    await axios.get(API_URL + `users/${item.id}`, {
                        headers: {
                            Authorization: 'Bearer ' + user.accessToken
                        }
                    }).then((res) => {
                        temparray = [...temparray, {id: item.id, username: res.data.result.username}]
                        // setBlockedUsers([...blockedUsers, {id: item.id, username: res.data.result.username}])
                        setBlockedUsers(temparray)
                    })
                }
            })
            return temparray
        }).then((res) => {
            setBlockedUsers(temparray)
        })
    }

    useEffect(() => {
        getBlockedUsers()
    }, [])

    useEffect(() => {
        getBlockedUsers()
    }, [block])

    function Delete() {
        deleteUser(userData).then(() => {
            alert(t("accountdeleted"))
            navigate("/")

        }).catch((error) => {
            alert(error)
        });
    }

    function ChangePassword() {
        sendPasswordResetEmail(auth, user.email).then(() => {
            alert(t("passwordresetemailsent"))
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorCode + " " + errorMessage)
        });
    }

    // const [token, setToken] = useState('');
    // useEffect(() => {
    //     setToken(user.accessToken);
    //     // console.log(user.accessToken);
    // }, []);

    const unblockUser = (id) => {
        axios.put(API_URL + `users/delete/blockedusers/${user.uid}`,
            {blockedUsers: id}, {
                headers: {
                    Authorization: 'Bearer ' + user.accessToken
                }
            }).then((res) => {
            setBlockedUsers([])
            setBlocked(!block)
        })
    }

    return (
        <div className="container-contents">
            {/*<UserData token={token}/>*/}
            <div className="box-content settings">
                <Accordion flush>

                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{t("resetpassword")}</Accordion.Header>
                        <Accordion.Body>
                            <h2>{t("passwordresetemail")}.</h2>
                            <button className="button go-to" onClick={ChangePassword}>{t("resetpassword")}</button>
                            {' '}
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header>{t("deleteaccount")}</Accordion.Header>
                        <Accordion.Body>
                            <h2>{t("deleteaccounthere")}.</h2>
                            <button className="button go-to" onClick={Delete}>{t("deleteaccount")}</button>
                            {' '}
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>{t("blockedusers")}</Accordion.Header>
                        <Accordion.Body>
                            {blockedUsers.length > 0 ? blockedUsers.map((item, index) =>
                                <div className="zip-result-row">
                                    <div className="column">
                                        <div className="zip-result-card">
                                            <Link to={'/userProfile'} state={{uid: item.uid}}>
                                                <img className="circle-img"
                                                     src={`https://firebasestorage.googleapis.com/v0/b/united-parents-hub.appspot.com/o/images%2F${item.uid}?alt=media&token=b7213d01-a694-4828-9b90-e7edb5b92df1`}
                                                     alt="user-img"
                                                     onError={(e) => {
                                                         if (e.target.src !== "/images/Profile_avatar_placeholder_large.png") {
                                                             e.target.onError = null;
                                                             e.target.src = '/images/Profile_avatar_placeholder_large.png'
                                                         }
                                                     }}
                                                ></img>
                                                <h1>{item.username}</h1>
                                            </Link>
                                            <button className="button go-to"
                                                    onClick={() => unblockUser(item.id)}>UNBLOCK
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            ) : <h2>{t("noblockedusers")}</h2>}

                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header>{t("privacydeclaration")}</Accordion.Header>
                        <Accordion.Body><DSGVO/></Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                        <Accordion.Header>{t("impressum")}</Accordion.Header>
                        <Accordion.Body><Impressum/></Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
}

export default Settings;