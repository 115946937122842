import React from "react";
import {welcomeMessage} from "../utils/welcomeMessage";
import {useTranslation} from "react-i18next";

/**
 * Box for motivation slogan displayed at the Home page
 * Filled with content provided from welcomeMessage.js
 * @returns Motivation component
 */

function Motivation(props) {

    const {togglePopUp, content, contentEN} = props
    const {t, i18n} = useTranslation()

    return (
        <div className="box-content home">
            <div className="home-hlp box-icon help-btn">
                <span className="material-icons" onClick={togglePopUp}>contact_support</span>
            </div>
            {content?.length > 0 ? i18n.language === 'en' && contentEN?.length > 0 ?
                    <p>{contentEN.split('\n').map(str => <p>{str}</p>)}</p> :
                    <p>{content.split('\n').map(str => <p>{str}</p>)}</p> :
                welcomeMessage.messageShort}
        </div>
    )
}

export default Motivation;