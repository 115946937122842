import {useTranslation} from "react-i18next";
import {CHRISTINA_EMAIL, CHRISTINA_PHONE} from "../config/Constants";
import {useNavigate} from "react-router-dom";

export function Kontakt() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    return (
        <div className="container-contents">
            <button className="text-link" onClick={() => navigate(-1)}>{t("back")}</button>
            <div className="box-content">
                <h1 className="contact-header">{t("contact-us")}</h1>
                <h2>{t("contact-text")}</h2>
                <h2>{t("contact-text-continuation")}:</h2>
                <p><strong>{t("responsible-person-female")}:</strong><br/>
                    Christina Jones<br/>
                    {t("phone")}: {CHRISTINA_PHONE}<br/>
                    {t("email")}: <a href={`mailto:${CHRISTINA_EMAIL}`}>{CHRISTINA_EMAIL}</a>
                </p>
            </div>
        </div>
    )
}