import React from "react"
import {Accordion} from "react-bootstrap"
import {guData} from "../utils/guData"
import HelpCard from "./HelpCard";
import {useTranslation} from "react-i18next";

/**
 * Accordion for the Help & Finances page
 * Function to create HelpCard components filled with info provided from helpCardData.js
 * @param {*} guData
 * @returns Accordion component
 */

function createCard(guData) {
    return (
        <HelpCard
        title={guData.title}
        content={null}
        URL={guData.URL}            
        />        
    )
}

function GuAccordion() {

    const {t, i18n} = useTranslation();

    return (
        <Accordion flush>

            <Accordion.Item eventKey="0">
                <Accordion.Header>{t("guidance")}</Accordion.Header>
                <Accordion.Body>
                    {guData.consulting.map(createCard)}
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
                <Accordion.Header>{t("childcare")}</Accordion.Header>
                <Accordion.Body>
                    {guData.childcare.map(createCard)}
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
                <Accordion.Header>{t("finances")}</Accordion.Header>
                <Accordion.Body>
                    {guData.finances.map(createCard)}
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
                <Accordion.Header>{t("legalframework")}</Accordion.Header>
                <Accordion.Body>
                    {guData.legal.map(createCard)}
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
                <Accordion.Header>{t("childoncampus")}</Accordion.Header>
                <Accordion.Body>
                    {guData.kidsCampus.map(createCard)}
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
                <Accordion.Header>{t("targetedinformation")}</Accordion.Header>
                <Accordion.Body>
                    {guData.specificInfo.map(createCard)}
                </Accordion.Body>
            </Accordion.Item>

        </Accordion>
    )
}

export default GuAccordion