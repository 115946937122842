import React from "react";
import {useTranslation} from "react-i18next";

/**
 * Info Box for the Home Page
 * contains: imageURL, title, content & if URL provided: a button for further information
 * @param {*} props
 * @returns Box component
 */

function HomeInfoBox(props) {

    const { t, i18n } = useTranslation();

    return (
        <div className="box-content home">
            {/* Show Logo only when a source is given in homeData.js */}
            {props.imgSrc.length > 0
                ? <div className="uni-logo">
                        <img className="uni-logo-img" src={props.imgSrc} alt="logo-img"></img>
                    </div>
                : null}

            <h1>{props.title}</h1>
            {props.title.includes("Familienbüro") ?
            <div><img className="banner" src="./images/famburo_banner.jpg"></img></div> : null }
            <p>{props.content.split('\n').map(str => <p>{str}</p>)}</p>

            {/* Show Button only when a URL is given */}
            {props.linkURL.length > 0
                ? <button className="button go-to" onClick={() => window.open(props.linkURL)}>{t("moreinfo")}</button>
                : null}
        </div>
    )
}

export default HomeInfoBox