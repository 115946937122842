import React from "react";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Footer() {
    const location = useLocation()
    const {t} = useTranslation()

    if (location.pathname !== "/impressum")
        return (<div className="footer">
            <Link to="/impressum">Impressum</Link>
            <Link to="/kontakt">{t("contact-us")}</Link>
        </div>)
    else return null
}

export default Footer;