import React from "react";
import HelpAccordion from "../components/HelpAccordion";
import MoreHelpList from "../components/MoreHelpList";
import {useUserAuth} from "../context/UserAuthContext"
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

/**
 * Help & Finances Content
 * @returns Help & Finances page
 */

function Help() {
    const {user} = useUserAuth();
    const {t, i18n} = useTranslation();

    return (

        <div className="container-contents">
            {user.email?.includes("@united-parents.de") ?
                <Link className="button go-to" to="/editHelpAndFinance">Seite Bearbeiten</Link> : <div></div>}
            <div className="box-content help">
                <div className="box-logo uni-logo">
                    <img src="./images/fra-uas-logo.svg" className="img fra-uas-img" alt="logo-img"></img>
                </div>
                <h1>{t("familyoffice")} - {t("infosforparents")}</h1>
                <div><img className="banner" src="./images/famburo_banner.jpg"></img></div>

                <HelpAccordion/>
            </div>


            <div className="box-content more-help">
                <h1>{t("moresupportin")} Frankfurt</h1>
                <br></br>
                <MoreHelpList/>
            </div>
        </div>
    );
}

export default Help;