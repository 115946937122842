import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useUserAuth} from "../context/UserAuthContext";
import Header from "./Header";
import Sidebar from "./Sidebar";
import {getData} from "../helpers/HttpService";

/**
 * Protected routes for auth users
 * @param {*} param0
 * @returns fixed Header & Sidebar component and children=routes for auth users
 */

function ProtectedRoutes({children, logoutOnlySidebar = false}) {
    const navigate = useNavigate();
    const {user, loading} = useUserAuth(); // Use loading state from context

    useEffect(() => {
        if (!loading && user === null) {
            navigate("/login");
        } else if (user && !user.emailVerified) {
            console.log(`e-mail not verified: ${user}`)
            navigate("/email-verification")
        }
    }, [user, loading, navigate]);

    if (loading ){
        // Render a loading state or null while checking authentication
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Header/>
            <Sidebar logoutOnly={logoutOnlySidebar}/>
            <div className="content-surface">{children}</div>
        </div>
    );
}

export default ProtectedRoutes;
