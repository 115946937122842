import React, {useEffect, useState} from "react"
import {Accordion} from "react-bootstrap"
import HelpCard from "./HelpCard"
import axios from "axios"
import {useTranslation} from "react-i18next";
import {API_URL} from "../helpers/HttpService";

function HelpAccordion() {

    const [contactContent, setContactContent] = useState('');
    const [contactTitle, setContactTitle] = useState('');
    const [contactUrl, setContactUrl] = useState('');

    const [careContent1, setCareContent1] = useState('');
    const [careTitle1, setCareTitle1] = useState('');
    const [careUrl1, setCareUrl1] = useState('');

    const [careContent2, setCareContent2] = useState('');
    const [careTitle2, setCareTitle2] = useState('');
    const [careUrl2, setCareUrl2] = useState('');

    const [careContent3, setCareContent3] = useState('');
    const [careTitle3, setCareTitle3] = useState('');
    const [careUrl3, setCareUrl3] = useState('');

    const [careContent4, setCareContent4] = useState('');
    const [careTitle4, setCareTitle4] = useState('');
    const [careUrl4, setCareUrl4] = useState('');

    const [financeContent1, setFinanceContent1] = useState('');
    const [financeTitle1, setFinanceTitle1] = useState('');
    const [financeUrl1, setFinanceUrl1] = useState('');

    const [financeContent2, setFinanceContent2] = useState('');
    const [financeTitle2, setFinanceTitle2] = useState('');
    const [financeUrl2, setFinanceUrl2] = useState('');

    const [pregnancyContent1, setPregnancyContent1] = useState('');
    const [pregnancyTitle1, setPregnancyTitle1] = useState('');
    const [pregnancyUrl1, setPregnancyUrl1] = useState('');

    const [pregnancyContent2, setPregnancyContent2] = useState('');
    const [pregnancyTitle2, setPregnancyTitle2] = useState('');
    const [pregnancyUrl2, setPregnancyUrl2] = useState('');

    const [parentsContent1, setParentsContent1] = useState('');
    const [parentsTitle1, setParentsTitle1] = useState('');
    const [parentsUrl1, setParentsUrl1] = useState('');

    const [parentsContent2, setParentsContent2] = useState('');
    const [parentsTitle2, setParentsTitle2] = useState('');
    const [parentsUrl2, setParentsUrl2] = useState('');

    const [studyOrgaContent1, setStudyOrgaContent1] = useState('');
    const [studyOrgaTitle1, setStudyOrgaTitle1] = useState('');
    const [studyOrgaUrl1, setStudyOrgaUrl1] = useState('');

    const [studyOrgaContent2, setStudyOrgaContent2] = useState('');
    const [studyOrgaTitle2, setStudyOrgaTitle2] = useState('');
    const [studyOrgaUrl2, setStudyOrgaUrl2] = useState('');

    const [supportContent1, setSupportContent1] = useState('');
    const [supportTitle1, setSupportTitle1] = useState('');
    const [supportUrl1, setSupportUrl1] = useState('');

    const [supportContent2, setSupportContent2] = useState('');
    const [supportTitle2, setSupportTitle2] = useState('');
    const [supportUrl2, setSupportUrl2] = useState('');

    const [connectionContent, setConnectionContent] = useState('');
    const [connectionTitle, setConnectionTitle] = useState('');
    const [connectionUrl, setConnectionUrl] = useState('');

    const {t, i18n} = useTranslation();

    useEffect(() => {


        const getContent = async () => {
            try {
                let response = await axios.get(API_URL + `helpAndFinance/`
                )

                console.log(response)

                setContactContent(response.data
                    ?.[0].contactContent)
                setContactTitle(response.data
                    ?.[0].contactTitle)
                setContactUrl(response.data
                    ?.[0].contactUrl)
                setCareContent1(response.data
                    ?.[0].careContent1)
                setCareTitle1(response.data
                    ?.[0].careTitle1)
                setCareUrl1(response.data
                    ?.[0].careUrl1)
                setCareContent2(response.data
                    ?.[0].careContent2)
                setCareTitle2(response.data
                    ?.[0].careTitle2)
                setCareUrl2(response.data
                    ?.[0].careUrl2)
                setCareContent3(response.data
                    ?.[0].careContent3)
                setCareTitle3(response.data
                    ?.[0].careTitle3)
                setCareUrl3(response.data
                    ?.[0].careUrl3)

                setCareContent4(response.data
                    ?.[0].careContent4)
                setCareTitle4(response.data
                    ?.[0].careTitle4)
                setCareUrl4(response.data
                    ?.[0].careUrl4)

                setFinanceContent1(response.data
                    ?.[0].financeContent1)
                setFinanceTitle1(response.data
                    ?.[0].financeTitle1)
                setFinanceUrl1(response.data
                    ?.[0].financeUrl1)

                setFinanceContent2(response.data
                    ?.[0].financeContent2)
                setFinanceTitle2(response.data
                    ?.[0].financeTitle2)
                setFinanceUrl2(response.data
                    ?.[0].financeUrl2)
                setPregnancyContent1(response.data
                    ?.[0].pregnancyContent1)
                setPregnancyTitle1(response.data
                    ?.[0].pregnancyTitle1)
                setPregnancyUrl1(response.data
                    ?.[0].pregnancyUrl1)

                setPregnancyContent2(response.data
                    ?.[0].pregnancyContent2)
                setPregnancyTitle2(response.data
                    ?.[0].pregnancyTitle2)
                setPregnancyUrl2(response.data
                    ?.[0].pregnancyUrl2)
                setParentsContent1(response.data
                    ?.[0].parentsContent1)
                setParentsTitle1(response.data
                    ?.[0].parentsTitle1)
                setParentsUrl1(response.data
                    ?.[0].parentsUrl1)
                setParentsContent2(response.data
                    ?.[0].parentsContent2)
                setParentsTitle2(response.data
                    ?.[0].parentsTitle2)
                setParentsUrl2(response.data
                    ?.[0].parentsUrl2)
                setStudyOrgaContent1(response.data
                    ?.[0].studyOrgaContent1)
                setStudyOrgaTitle1(response.data
                    ?.[0].studyOrgaTitle1)
                setStudyOrgaUrl1(response.data
                    ?.[0].studyOrgaUrl1)
                setStudyOrgaContent2(response.data
                    ?.[0].studyOrgaContent2)
                setStudyOrgaTitle2(response.data
                    ?.[0].studyOrgaTitle2)
                setStudyOrgaUrl2(response.data
                    ?.[0].studyOrgaUrl2)
                setSupportContent1(response.data
                    ?.[0].supportContent1)
                setSupportTitle1(response.data
                    ?.[0].supportTitle1)
                setSupportUrl1(response.data
                    ?.[0].supportUrl1)
                setSupportContent2(response.data
                    ?.[0].supportContent2)
                setSupportTitle2(response.data
                    ?.[0].supportTitle2)
                setSupportUrl2(response.data
                    ?.[0].supportUrl2)

                setConnectionContent(response.data
                    ?.[0].connectionContent)
                setConnectionTitle(response.data
                    ?.[0].connectionTitle)
                setConnectionUrl(response.data
                    ?.[0].connectionUrl)


            } catch (err) {
                if (err.response) {
                    console.log(err.response.data)
                    console.log(err.response.status)
                    console.log(err.response.headers)
                } else {
                    console.log(`error ${err.message}`)
                }
            }
        }

        getContent()

    }, []);

    return (
        <Accordion flush>

            <Accordion.Item eventKey="0">
                <Accordion.Header>{t("advicecontact")}</Accordion.Header>
                <Accordion.Body>
                    <HelpCard
                        title={contactTitle}
                        content={contactContent}
                        URL={contactUrl}
                    />

                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
                <Accordion.Header>{t("childcare")}</Accordion.Header>
                <Accordion.Body>
                    <HelpCard
                        title={careTitle1}
                        content={careContent1}
                        URL={careUrl1}
                    />

                    <HelpCard
                        title={careTitle2}
                        content={careContent2}
                        URL={careUrl2}
                    />

                    <HelpCard
                        title={careTitle3}
                        content={careContent3}
                        URL={careUrl3}
                    />
                    <HelpCard
                        title={careTitle4}
                        content={careContent4}
                        URL={careUrl4}
                    />

                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
                <Accordion.Header>{t("finances")}</Accordion.Header>
                <Accordion.Body>
                    <HelpCard
                        title={financeTitle1}
                        content={financeContent1}
                        URL={financeUrl1}
                    />
                    <HelpCard
                        title={financeTitle2}
                        content={financeContent2}
                        URL={financeUrl2}
                    />
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
                <Accordion.Header>{t("pregnancy")}</Accordion.Header>
                <Accordion.Body>
                    <HelpCard
                        title={pregnancyTitle1}
                        content={pregnancyContent1}
                        URL={pregnancyUrl1}
                    />
                    <HelpCard
                        title={pregnancyTitle2}
                        content={pregnancyContent2}
                        URL={pregnancyUrl2}
                    />
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
                <Accordion.Header>{t("studyorga")}</Accordion.Header>
                <Accordion.Body>
                    <HelpCard
                        title={studyOrgaTitle1}
                        content={studyOrgaContent1}
                        URL={studyOrgaUrl1}
                    />
                    <HelpCard
                        title={studyOrgaTitle2}
                        content={studyOrgaContent2}
                        URL={studyOrgaUrl2}
                    />
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
                <Accordion.Header>{t("parents")}</Accordion.Header>
                <Accordion.Body>
                    <HelpCard
                        title={parentsTitle1}
                        content={parentsContent1}
                        URL={parentsUrl1}
                    />
                    <HelpCard
                        title={parentsTitle2}
                        content={parentsContent2}
                        URL={parentsUrl2}
                    />
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
                <Accordion.Header>{t("caretaking")}</Accordion.Header>
                <Accordion.Body>
                    <HelpCard
                        title={supportTitle1}
                        content={supportContent1}
                        URL={supportUrl1}
                    />
                    <HelpCard
                        title={supportTitle2}
                        content={supportContent2}
                        URL={supportUrl2}
                    />
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
                <Accordion.Header>{t("connection")}</Accordion.Header>
                <Accordion.Body>
                    <HelpCard
                        title={connectionTitle}
                        content={connectionContent}
                        URL={connectionUrl}
                    />

                </Accordion.Body>
            </Accordion.Item>

        </Accordion>
    )
}

export default HelpAccordion